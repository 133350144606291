import React from 'react';
import Form from 'react-bootstrap/Form';








const Services = () => {
    return (
        <>



{/* section1 */}

<div className='abt-section pt-5 pb-5' id='srv-section'>


<div className='container'>

<h3 className=''>Services</h3>

<div className='bds'></div>
<div id='brdrs'></div>

{/* <p>It is a long established fact that a reader.</p> */}


</div>


</div>


{/* section2 */}

<div className='container abt-2 '>


<ul className='mt-5'>

<li className='mt-2'><b>University Selection: </b>Nayee Disha Education offers expert guidance in selecting the right university for students’ MBBS studies abroad. Consultants consider various factors such as academic reputation, curriculum, location, and student support services to help students make informed decisions.</li>
<li className='mt-2'>
<b>Application Assistance: </b>The team at Nayee Disha Education provides comprehensive support throughout the application process. Consultants assist students in preparing their applications, ensuring all required documents are submitted accurately and on time.</li>
<li className='mt-2'>
<b>Admission Guidance:</b> Nayee Disha Education offers step-by-step guidance to students during the admission process. Consultants provide information on admission requirements, entrance exams, interviews, and any specific criteria set by the universities.</li>
<li className='mt-2'>
<b>Visa Support:</b> Navigating the visa application process can be complex. Nayee Disha Education assists students in understanding visa requirements, completing necessary documentation, and preparing for visa interviews to enhance the chances of a successful visa approval.</li>
<li className='mt-2'>
<b>Pre-Departure Support: </b>Before students embark on their study abroad journey, Nayee Disha Education provides detailed guidance and support. This includes assistance with travel arrangements, accommodation options, and essential preparations to ensure a smooth transition to the chosen study destination.</li>
<li className='mt-2'>
<b>Post-Arrival Assistance:</b> Upon students’ arrival, Nayee Disha Education continues to offer support. Consultants provide guidance on university registration, orientation programs, and help students acclimate to their new environment.</li>
<li className='mt-2'>
<b>Academic Support:</b> Nayee Disha Education believes in the academic success of its students. Consultants provide guidance on study techniques, time management, and offer resources to help students excel in their MBBS program.</li>
<li className='mt-2'>
<b>Student Welfare Services:</b> The well-being of students is a top priority for Nayee Disha Education. The organization offers assistance in accessing healthcare services, understanding local laws and regulations, and ensures students are aware of available support systems within their chosen study destination.</li>
<li className='mt-2'>
<b>Career Guidance:</b> Nayee Disha Education provides career guidance to students, helping them navigate the options and pathways available after completing their MBBS degree. Consultants offer insights into residency programs, licensing requirements, and other professional development opportunities.</li>
<li className='mt-2'>
<b>Alumni Network:</b> Nayee Disha Education fosters a strong alumni network, connecting students with successful graduates who can provide guidance, mentorship, and networking opportunities to support students’ future endeavors.
These services are designed to provide comprehensive support to students pursuing MBBS programs abroad, ensuring a smooth and successful educational journey.</li>





</ul>


</div>




                        {/* FAQ SECTION */}

                        <div className='container faq-section mt-5'>


                            <div className='card'>
                                <div className='card-body'>

                                    <h3>FAQs about DIT University</h3>

                                    <div className="accordion mt-4" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header mb-2" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What countries do you assist students in studying MBBS?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                Nayee Disha Education assists students in studying MBBS in countries such as Russia, Bangladesh, and other renowned destinations known for their quality medical education.
                                                </div>
                                            </div>
                                        </div><hr></hr>
                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                How do you help students select the right university?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Our experienced consultants guide students in selecting the right university by considering factors like academic reputation, curriculum, location, language requirements, and student support services. We provide personalized advice based on individual preferences and goals.
                                                </div>
                                            </div>
                                        </div><hr></hr>
                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Can you help with the application process?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                Absolutely! Nayee Disha Education provides comprehensive assistance throughout the application process. We help students prepare their applications, review documents, and ensure timely submission.
                                                </div>
                                            </div>
                                        </div><hr></hr>

                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                Do you provide guidance on admission requirements and entrance exams?
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Yes, our team is well-versed in the admission requirements of various universities. We provide guidance on entrance exams, including information on exam formats, preparation strategies, and resources to help students achieve their best performance.
                                                </div>
                                            </div>
                                        </div><hr></hr>

                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                How do you assist students with visa applications?
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Nayee Disha Education offers support with visa applications. Our consultants provide detailed information on visa requirements, assist in completing necessary documentation, and offer guidance for visa interviews, increasing the chances of a successful visa approval.
                                                </div>
                                            </div>
                                        </div><hr></hr>

                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                What kind of support is provided before departure?
                                                </button>
                                            </h2>
                                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Prior to departure, we offer comprehensive pre-departure support. This includes guidance on travel arrangements, accommodation options, essential preparations, and any additional information to help students smoothly transition to their chosen study destination.
                                                </div>
                                            </div>
                                        </div><hr></hr>

                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                Will I receive support after I arrive at the university?
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Yes, Nayee Disha Education provides support even after students arrive at their respective universities. Our team remains accessible to address any concerns or queries, helping students with university registration, orientation, and acclimation to their new environment.
                                                </div>
                                            </div>
                                        </div><hr></hr>

                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                Do you provide academic support during the program?
                                                </button>
                                            </h2>
                                            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Absolutely! We offer academic support to students throughout their MBBS program. Our consultants provide guidance on study techniques, time management, and resources to help students excel in their studies.
                                                </div>
                                            </div>
                                        </div><hr></hr>

                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                Can you assist with career guidance after completing the MBBS degree?
                                                </button>
                                            </h2>
                                            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Yes, Nayee Disha Education offers career guidance to students after completing their MBBS degree. We provide insights into residency programs, licensing requirements, and other professional development opportunities to support students’ future endeavors.
                                                </div>
                                            </div>
                                        </div><hr></hr>


                                        <div className="accordion-item mt-4">
                                            <h2 className="accordion-header mb-2" id="headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                Is there a network for alumni to connect with other graduates?
                                                </button>
                                            </h2>
                                            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Yes, Nayee Disha Education fosters a strong alumni network. We connect students with successful graduates who can provide mentorship, guidance, and networking opportunities to support their professional growth.
                                                </div>
                                            </div>
                                        </div><hr></hr>
                                    </div>

                                </div>
                            </div>
                        </div>










                        {/* Register With Us */}



                        <div class="container mt-5 mb-5 reg-section">

                            <div className='text-center '> <h3>Register with us</h3> </div>


                            <div className="row mt-4">
                                <div className="col-md-2 mt-3">
                                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" />
      </Form.Group> */}
                                    <Form.Control type="text" placeholder="Name" />
                                </div>
                                <div className="col-md-2 mt-3">
                                    <Form.Group className="" controlId="exampleForm.ControlInput1">

                                        <Form.Control type="email" placeholder="Mobile" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 mt-3">
                                    <Form.Group className="" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="email" placeholder="Email" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 mt-3">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected> Select Level </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="col-md-2 mt-3">
                                    <Form.Group className="mb-3" controlId="">

                                        <select class="form-select" aria-label="Default select example">
                                            <option selected>Select Stream</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 mt-3">
                                    <button type="button" class="btn btn-primary">Submit details</button>
                                </div>
                            </div>
                        </div>












        </>

                
);
};

export default Services;


import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
const ZhejiangUniversityChina = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch('https://webcrmapi.nayeedisha.in/api/university-details')
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);
        console.log(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>



      {/* section1 */}

      <div className='abt-section pt-5 pb-5'>


        <div className='container'>

          <h3>MBBS in Zhejiang University School of Medicine, China</h3>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>Welcome to the Nayee Disha page dedicated to MBBS in Zhejiang University School of Medicine, China. Zhejiang University School of Medicine, located in the picturesque city of Hangzhou, is renowned for its excellence in medical education and research. As a leading institution in China, the school offers a comprehensive MBBS program that provides students with a solid foundation in medicine and prepares them for a successful career in the field. This page serves as a gateway to explore the remarkable opportunities and experiences awaiting students pursuing MBBS at Zhejiang University School of Medicine, showcasing the exceptional educational environment, faculty expertise, and state-of-the-art facilities that make it an ideal destination for aspiring medical professionals.</p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Advantages of Zhejiang University School of Medicine, China</h3>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Global Recognition</b>
                  <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Affordable Education</b>
                  <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Experienced Faculty</b>
                  <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Cultural Diversity</b>
                  <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Advanced Infrastructure</b>
                  <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>High-Quality Teaching</b>
                  <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>English-Medium</b>
                  <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Career Opportunities</b>
                  <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                </div>

              </div>
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Facts about Zhejiang University School of Medicine, China</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; Established Legacy: Zhejiang University School of Medicine has a rich history that dates back to 1912, making it one of the oldest and most esteemed medical schools in China.</li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Academic Excellence: The school is consistently ranked among the top medical institutions in China and is known for its high academic standards and rigorous medical programs.</li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp; Comprehensive MBBS Program: Zhejiang University School of Medicine offers a comprehensive and internationally recognized MBBS program that equips students with the knowledge and skills required for a successful medical career.</li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; Diverse Specializations: The school provides a wide range of specialization options, allowing students to pursue their interests in areas such as clinical medicine, preventive medicine, medical imaging, and more.</li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; Faculty Expertise: Zhejiang University School of Medicine boasts a distinguished faculty consisting of experienced professors and medical professionals who are dedicated to delivering quality education and mentoring students.</li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; Cutting-edge Facilities: The school is equipped with state-of-the-art facilities, including modern laboratories, simulation centers, and affiliated teaching hospitals, providing students with hands-on training and exposure to advanced medical technologies.</li>
              <li className='mt-2 '><Bs7CircleFill /> &nbsp; Research Advancements: Zhejiang University School of Medicine is renowned for its contributions to medical research, with ongoing studies in various fields, including oncology, neurology, genetics, and public health.</li>
              <li className='mt-2 '><Bs8CircleFill /> &nbsp; International Collaborations: The school has established partnerships with prestigious international institutions, fostering academic exchanges, joint research projects, and opportunities for international exposure.</li>
              <li className='mt-2 '><Bs9CircleFill /> &nbsp; Clinical Training: Through its affiliation with top-tier teaching hospitals, Zhejiang University School of Medicine offers students extensive clinical training, allowing them to gain practical skills and experience in real medical settings.</li>
            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

        <img src={Admissionimg} alt="img" className='w-100 mt-3' />
      </div>

      <br></br>
      <div className="container">
        <h3 className='border_before'>Latest Colleges</h3>
        <div className="row">
          <div className="my-5">


            {isLoading ? (
              <div className='text-center'>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (

              <div className='row'>
                <OwlCarousel className="owl-theme" {...options} >
                  {universities.map((data) => {
                    return (

                      <div className="items">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>

                          <img src={lister1} class="card-img-top" alt="" />

                          <div className="card-body iconerss-card">
                            <img src={iconlister} class="" alt="" />
                            {/* 
                   <h5 className="mt-4">Kazan Federal <br></br>University</h5>
                   */}
                            <Link to={`/university/${data.slug}`}>
                              <h5 className="mt-4 text-transform">{data.name}</h5>
                            </Link>
                            <div>
                              <div className='mysvg'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                                <span id='one-svg'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                  </svg>
                                </span>
                                &nbsp;&nbsp; 64 Reviews
                              </div>
                              <div className=' pt-2'>
                                <p className='para1'>Establish Year-<span className='ugc'>  {data.estb ? data.estb : 'Not found'} </span></p>



                                <p className='para1'>University Type-<span className='ugc'> {data.typeofuniversity ? data.typeofuniversity : 'Not found'} </span></p>
                                <p className='para1'>Country-<span className='ugc'> {data.country ? data.country : 'Not found'} </span></p>

                              </div>
                              <div className='d-flex justify-content-between'>
                                <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                  Apply Now &nbsp;&nbsp;&nbsp;
                                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                  </svg>
                                </Button>
                                <Link to={`/university/${data.uid}`}>
                                  <Button className="btn" variant="outline-success">Read More</Button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    )
                  })
                  }
                </OwlCarousel>
              </div>

            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default ZhejiangUniversityChina;






import { React, useState, useEffect } from 'react';
import forwardarrow from '../img/ani.png';
import universitylogo from '../img/ani.png';
import loanimg from '../img/loan-img.svg';
import testimg from '../img/ani.png';
import rec1 from '../img/loan-img.svg';
import dimg from '../img/d-img.jpg';
import Form from 'react-bootstrap/Form';
import { Triangle } from 'react-loader-spinner'
import galleriestab from '../img/ani.png';
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import Table from 'react-bootstrap/Table';
const Exam = () => {
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [exams, setExams] = useState([]);
    useEffect(() => {
        fetch(`https://webcrmapi.nayeedisha.in/api/exam-details/${slug}`)
            .then(response => response.json())
            .then(data => {
                setExams(data);
                setIsLoading(false);
                console.log('Exams Data', data);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }, [slug]);
    // Assuming you have received the contact details string in the variable contactDetails
    const contactDetails = "Email: jeemain@nta.ac.in, Phone: +91-7703859909";

    // Regular expressions to extract email and phone number
    const emailRegex = /Email:\s*([\w.-]+@[\w.-]+\.\w+)/;
    const phoneRegex = /Phone:\s*([+\d-]+)/;

    // Extracting email and phone number from the contact details string
    const emailMatch = contactDetails.match(emailRegex);
    const phoneMatch = contactDetails.match(phoneRegex);
    //   word test limit
    const truncateWords = (text, limit) => {
        if (!text) return ''; // Check if text is not provided
        const words = text.split(' ');
        return words.slice(0, limit).join(' ');
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
    };
    return (
        <>
            {/* section1 */}
            {isLoading ? (
                <div className='d-flex justify-content-center py-5'>
                    <Triangle
                        visible={true}
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            ) : (
                exams && (
                    <div className='university-section pt-5 pb-5' key={exams.id}>
                        <div className='container'>
                            <div className='first-section d-flex'>
                                <div className='third-section pt-2'>
                                    <p> {exams.exam_name ? exams.exam_name : 'Null'}</p>
                                    <div className='mysvgs '>
                                        <img src="" alt='' />
                                        <span className='para1'>Conducting dy:<span className='ugc'> {exams.conducting_body ? exams.conducting_body : 'Not Found'} </span></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
            {isLoading ? (
                <p></p>
            ) : (
                exams && (
                    <div className='container pt-5'>
                        <nav>
                            <div class="nav nav-tabs mobile-navflex" id="nav-tab" role="tablist" >
                                <a href='#nav-overview-tab'>
                                    <button class="nav-link active" id="nav-overview-tab" data-bs-toggle="tab" data-bs-target="#nav-overview" type="button" role="tab" aria-controls="nav-overview" aria-selected="true">Eligibility</button>&nbsp;&nbsp;

                                </a>
                                <a href='#nav-courses-tab'>
                                    <button class="nav-link" id="nav-courses-tab" data-bs-toggle="tab" data-bs-target="#nav-courses" type="button" role="tab" aria-controls="nav-courses" aria-selected="false">How to Prepare</button>&nbsp;&nbsp;
                                </a>
                                <a href="#nav-program-tab">
                                <button class="nav-link" id="nav-program-tab" data-bs-toggle="tab" data-bs-target="#nav-program" type="button" role="tab" aria-controls="nav-program" aria-selected="false">Exam Details</button>&nbsp;&nbsp;
                              </a>
                                <button class="nav-link" id="nav-admission-tab" data-bs-toggle="tab" data-bs-target="#nav-admission" type="button" role="tab" aria-controls="nav-admission" aria-selected="false">Admission</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-scholarship-tab" data-bs-toggle="tab" data-bs-target="#nav-scholarship" type="button" role="tab" aria-controls="nav-scholarship" aria-selected="false">Scholarship</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-placement-tab" data-bs-toggle="tab" data-bs-target="#nav-placement" type="button" role="tab" aria-controls="nav-placement" aria-selected="false">Placements</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-cutoff-tab" data-bs-toggle="tab" data-bs-target="#nav-cutoff" type="button" role="tab" aria-controls="nav-cutoff" aria-selected="false">CutOffs</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-campus-tab" data-bs-toggle="tab" data-bs-target="#nav-campus" type="button" role="tab" aria-controls="nav-campus" aria-selected="false">Campus</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-gallery-tab" data-bs-toggle="tab" data-bs-target="#nav-gallery" type="button" role="tab" aria-controls="nav-gallery" aria-selected="false">Gallery</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-reviews-tab" data-bs-toggle="tab" data-bs-target="#nav-reviews" type="button" role="tab" aria-controls="nav-reviews" aria-selected="false">Reviews</button>&nbsp;&nbsp;
                            </div>
                        </nav>
                        <section id='#nav-overview-tab'>
                            <div className='container mt-3'>
                                <div className="card">
                                    <div className="card-body overview">
                                        <h2>Eligibility</h2>
                                        <div className='overview-para'>
                                            {exams.exam_level ? exams.eligibility : 'Null'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* How to Prepare */}
                        <section id="#nav-courses-tab">
                            <div className='container mt-3'>
                                <div className="card">
                                    <div className="card-body overview exam-bg">
                                        <h2>How to Prepare</h2>
                                        <div className='overview-para'>
                                            {exams.how_to_prepare ? exams.how_to_prepare : 'Null'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id="#nav-courses-tab">
                            <div className='container mt-3'>
                                <div className="card">
                                    <div className="card-body overview">
                                        <h2>Cut off</h2>
                                        <div className='overview-para'>
                                            {exams.cut_off ? exams.cut_off : 'Null'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id="#nav-courses-tab">
                            <div className='container mt-3'>
                                <div className="card">
                                    <div className="card-body overview exam-bg">
                                        <h2>Courses Offered</h2>
                                        <div className='overview-para'>
                                            {exams.courses_offered ? exams.courses_offered : 'Null'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr />
                        <section id="#nav-program-tab">
                            <div className='container mt-5 mb-3'>
                                <div className="card">
                                    <div className="card-body overview">
                                        <h2 className='py-3'>{exams.exam_name} Highlights 2024</h2>
                                        <Table className='mt-2' striped bordered hover >
                                            <thead>
                                                <tr>

                                                    <th>Exam Details</th>

                                                    <th>Particulars</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Exam Name</td>
                                                    <td>{exams.exam_name ? exams.exam_name : 'Null'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Conducting Body</td>
                                                    <td>{exams.conducting_body ? exams.conducting_body : 'Null'}</td>
                                                </tr>

                                                <tr>

                                                    <td>Exam Level</td>
                                                    <td>{exams.exam_level ? exams.exam_level : 'Null'}</td>



                                                </tr>

                                                <tr>

                                                    <td>Exam Frequency</td>
                                                    <td>{exams.exam_frequency ? exams.exam_frequency : 'Null'}</td>



                                                </tr>

                                                <tr>

                                                    <td>Mode of Exam</td>
                                                    <td>{exams.mode_of_exam ? exams.mode_of_exam : 'Null'}</td>



                                                </tr>

                                                <tr>

                                                    <td>Courses Offered</td>
                                                    <td>{exams.courses_offered ? exams.courses_offered : 'Null'}</td>



                                                </tr>

                                                <tr>

                                                    <td>Total Seats</td>
                                                    <td>{exams.total_seats ? exams.total_seats : 'Null'}</td>



                                                </tr>
                                                <tr>

                                                    <td>Exam Duration</td>
                                                    <td>{exams.exam_duration ? exams.exam_duration : 'Null'}</td>



                                                </tr>
                                                <tr>

                                                    <td>Num of Papers</td>
                                                    <td>{exams.num_papers ? exams.num_papers : 'Null'}</td>



                                                </tr>
                                                <tr>

                                                    <td>Medium of Exam</td>
                                                    <td>{exams.medium_of_exam ? exams.medium_of_exam : 'Null'}</td>



                                                </tr>
                                                <tr>

                                                    <td>Official Website</td>
                                                    <td><a href=''>{exams.official_website ? exams.official_website : 'Null'}</a></td>
                                                </tr>
                                                <tr>
                                                    <td>Contact Details</td>
                                                    <td>
                                                        <ul className='pl-1'>
                                                            {emailMatch && <li>Email: {emailMatch[1]}</li>}
                                                            {phoneMatch && <li>Phone: {phoneMatch[1]}</li>}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <br></br>
                    </div>

                )
            )}
        </>
    );
};
export default Exam;
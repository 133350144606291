import {React,useState} from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ProSidebarProvider } from 'react-pro-sidebar';
import "./index.css";
import "./App.css";
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Router>

  <App />


  </Router>, rootElement);
} else {
  ReactDOM.render(<Router><App /></Router>, rootElement);


}

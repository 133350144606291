import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import Button from 'react-bootstrap/Button';
import { Link, useParams } from 'react-router-dom';
const NewsDetails = () => {
    const options = {
        loop: true,
        margin: 10,
        items: 1,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
                nav: true,

            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    };
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [newsdetails, setNewsdetails] = useState([]);
    useEffect(() => {
        fetch(`https://webcrmapi.nayeedisha.in/api/news-details/${slug}`)
            .then(response => response.json())
            .then(data => {
                setNewsdetails(data);
                setIsLoading(false);
                console.log('Exams Data', data);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }, [slug]);

    // latest news
    const [latestNews, setLatestNews] = useState([]);
    useEffect(() => {
       fetch('https://webcrmapi.nayeedisha.in/api/news-details')

          .then((response) => response.json())
          .then((data) => {
             // Assuming 'results' contains the array of universities
             setLatestNews(data);
             setIsLoading(false);
             console.log('News Data', data);
          })
          .catch((error) => {
             setIsLoading(false);
             console.error('Error fetching data:', error);
          });
    }, []);



    return (
        <>



            {/* section1 */}

            <div className='abt-section-news pt-5 pb-5'>


                <div className='container'>

                    <h3 className="text-white">{newsdetails.title}</h3>

                    <div className='bds'></div>
                    <div id='brdrs'></div>

                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>




            <div className='container abt-2 mt-5 mb-5'>

                <div className="row">
                    <div className="col-md-9">
                        <div className="news-paragraphbg">
                        {/* <h3 className="news-bg">{newsdetails.title}</h3> */}
                       
                     
                        <p className="mx-2">Created on 19-Apr-2024</p>
                        <p className='mb-4 news-paragraph'>{newsdetails.content}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className="section5">
                        <div className="latest-news">
                        <h3>Recent News</h3>
                        </div>

     
   
               {isLoading ? (
                  <div className='text-center'>
                     <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                     </Spinner>
                  </div>
               ) : (
                  <div className="row1">
                   {latestNews.slice(0,6).map((newsdetails) => {
                        const truncatedContent = newsdetails.content?.split(' ').slice(0, 30).join(' ');
                        // Convert created_at to a Date object
                        const createdAtDate = new Date(newsdetails.created_at);

                        // Define months array
                        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                        // Get day, month, and year
                        const day = createdAtDate.getDate();
                        const monthIndex = createdAtDate.getMonth();
                        const year = createdAtDate.getFullYear();

                        // Format the date string
                        const formattedDate = `${day} ${months[monthIndex]} ${year}`;
                        return (
                         
              
                                 <div className="card-body crdr1 ">
                                   
                                       
                                   <Link to={`/news-details/${newsdetails.slug}`}>  <b className="f-15" style={{ textTransform: 'capitalize' }}>{newsdetails.title ? newsdetails.title : 'Not found'}</b></Link>
                                          <p>{formattedDate}</p>
                                     
                                          
                                
                                     
                                    </div>
                                
                          
                        
                        )
                     })
                     }
                  </div>
               )}
            </div>

     
    
                    </div>
                </div>









            </div>


        </>
    );
};

export default NewsDetails;

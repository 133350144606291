import React from 'react';
import nayidishalogofooter from '../img/nayidishalogofooter.png';
import nayidishafooter from '../img/nayidishafooter.svg'

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import otp from './otp.svg';
import sideimg from './side-img.png';
import { Link } from "react-router-dom";
import nayeedishafooterfin from '../img/nayeedishafooter-fin.svg';
import { IoIosCloseCircle } from "react-icons/io";
import HorizontalLinearStepper from '../pages/registration-steper/horizontalLinearStepper'
import { Button } from 'react-bootstrap';
import CookieConsent, {Cookies, getCookieConsentValue } from "react-cookie-consent";
console.log(getCookieConsentValue(Cookies));
const Footer = () => {
  const handleAccept = () => {
    console.log('User accepted cookies.');
  };

  const handleDecline = () => {
    console.log('User declined cookies.');
  };
  return (
    <>

      <div className='footer1 p-5'>


        <div className='container'>


          <div className='row'>


            <div className='col-lg-3 col-6  '>
              <h5>MBBS Russia</h5>

              <a href='/mbbs-in-bashkir-state-medical-university'>MBBS in Bashkir State Medical University Russia</a>
              {/* <a href=''> Marketing</a> */}



            </div>



            <div className='col-lg-3 col-6 mt-4'>


              <a href='/mbbs-in-chuvash-state-medical-university-russia'> MBBS in Chuvash State Medical University Russia</a>
              {/* <a href=''> Business Analytics</a> */}



            </div>



            <div className='col-lg-3 col-6 mt-4'>


              <a href='/mbbs-in-omsk-state-medical-university-russia'> MBBS in OMSK State Medical University Russia</a>
              {/* <a href=''> Logistics and Supply chain</a> */}



            </div>


            {/* 
            <div className='col-md-3 col-6 mt-4'>


              <a href=''> Data Science</a> <br></br>
              <a href=''> Data Science Analytics</a>



            </div> */}


            <div className='col-lg-3 col-6 mt-4'>


              <a href='/mbbs-in-crimea-federal-university-russia'> MBBS in Crimea Federal University Russia</a>
              {/* <a href=''> View More</a> */}



            </div>



          </div>


          <div className='row pt-5'>


            <div className='col-lg-4 col-6'>
              <h5>MBBS Philippines</h5>


              <a href='/mbbs-in-brokenshire-college-of-medicine-philippines'> MBBS in Brokenshire College of Medicine Philippines</a>
              {/* <a href=''> Marketing</a> */}



            </div>



            <div className='col-lg-4 col-6 mt-4'>


              <a href='/mbbs-in-our-lady-of-fatima-university-philippines'> MBBS in Our Lady of Fatima University Philippines</a>




            </div>



            <div className='col-lg-4 col-6 mt-4'>


              <a href='/mbbs-in-our-lady-of-fatima-university-philippines'>MBBS in University of Perpetual Help System Dalta Philippines</a>



            </div>






            {/* <div className='col-md-2 col-6 mt-4'>


              <a href=''> Healthcare Management</a> <br></br>
              <a href=''> View More</a>



            </div> */}


          </div>





          <div className='row pt-5'>

            <div className='col-lg-4  col-6'>


              <h5>MBBS Kyrgyzystan</h5>



              <a href='/mbbs-in-osh-state-medical-university-kyrgyzstan'> MBBS in OSH State Medical University Kyrgyzstan</a>
              {/* <a href=''> Marketing</a> */}



            </div>



            <div className='col-lg-4 col-6 mt-4'>


              <a href='/mbbs-in-jalalabad-state-university-kyrgyzstan'> MBBS in Jalalabad State University Kyrgyzstan</a>




            </div>



            <div className='col-lg-4 col-6 mt-4'>


              <a href='/mbbs-in-kazakh-national-medical-university-kyrgyzstan'>MBBS in Kazakh National Medical University Kyrgyzstan</a>



            </div>









          </div>




        </div>

      </div>

      <div className='footer2 p-5'>


      </div>

      <div className="footer3 p-5">

        <div className='container'>

          <div className='row'>

            <div className='col '>

              <a className="navbar-brand foot-res1" href="/"><img src={nayeedishafooterfin} alt="" style={{ width: "200px" }} />

              </a>

              <div className='pt-4 foot-res2'>
                <p> C56/A3, C Block, Phase 2, Industrial Area, Sector 62, Noida, Gautam Buddh Nagar Uttar Pradesh 201301</p>

                <a href="tel:+91-9621-040-317"><p>+91-9621-040-317</p></a>

                <p>info@nayeedisha.in</p>


              </div>

            </div>


            <div className='col pt-2 '>

              <h5>More To Explore</h5>
              <div className='pt-4'>
                <p>Common Application form</p>

                <p>Education Loan</p>

                <p>Insurance</p>

                <p>Job Ready Course</p>

                <p>Study Abroad</p>

              </div>

            </div>



            <div className='col pt-2'>

              <h5>Tools & Research</h5>
              <div className='pt-4'>
                <p>Career Compass</p>

                <p>Write a review</p>

                <p>QnA Forum</p>

                <p>DU Predictor</p>

                <p>Unit Calculator</p>

              </div>

            </div>


            <div className='col pt-2'>

              <h5>Quick Links</h5>
              <div className='pt-4'>
                <Link to={"/about-us"} ><p>About us</p></Link>

                <Link to={"/contactus"} >   <p>Contact us</p></Link>

                <p>Join us</p>

                <p>Boards</p>

                <p>Visual Story</p>

              </div>

            </div>


            <div className='col pt-2'>

              <h5>Follow</h5>
              <div className='pt-4'>
                <a href='https://www.facebook.com/nayeedishaMBBS'><p>Facebook</p></a>

                <a href='https://www.instagram.com/nayeedishaservices/'><p>Twitter</p></a>

                <p>Instagram</p>

                <p>Linkedin</p>

                <p>Youtube</p>

              </div>
            </div>



          </div>

        </div>


      </div>










      {/* modal */}

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div className=" modal-dialog modal-xl">
          <Button variant="secondary closebutton"><IoIosCloseCircle className='close_icon_width' /></Button>
          <div className="modal-content">
            <HorizontalLinearStepper />
          </div>

        </div>
      </div>






      {/* end modal */}








      {/* SECOND MODAL */}
      {/* modal start */}



      {/* 

 <div className="modal modals fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className=" modal-dialog modal-dialog-centered">
                          <div className="modal-content">

                            <div className="modal-body">


                              <div className='full-forms '>
                                <div className="container">
                                  <div className="row  full">

                                    <div className="col-md-6 col-12 back-form " >



                                    <img src={sideimg} alt="" style={{ width: "" }} />









                                    </div>

                                    <div className="col-lg-6 col-md-12 col-sm-12 ">
                                      <div className='back-nextform p-4'>
                                        <div className='d-flex'>
                                          <h5></h5>

                                          <button type="button" class="btn-close closer" data-bs-dismiss="modal" aria-label="Close"></button>

                                        </div>
  


                                        <div className='text-center mt-4' >  <img src={otp} alt="" style={{ width: "" }} />

                                        <h5 className='mt-3'>OTP Verification</h5>
                                        <p className='mt-3'>We will send you an one time password on your mobile number</p></div>
                                           
                                  
                                        <div id="form" className='mt-4'>

                                   


                                       




                                          <div className="form-group  ph-forms  ">
                                            <span className="wpcf7-form-control-wrap your-name ">
                                              <input name="phone" className='p-2' placeholder="   Phone Number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type="number" maxlength="10" required=""
                                          
                                              />

                                            </span></div>









                                          <div className='mt-3'> <p>By Submitting this form you accept and agree to our <a href="">Terms Of Use</a>.</p></div>






                                          <div className='mt-3 fr-but'>
                                            <button type="button" class="btn btn-primary">Generate OTP</button>
                                          </div>


                                          <div className='mt-3 last-para'> <p>Already A Member? <a href=''> Login Here </a>it’s easy!</p></div>



                                        </div>

                                      </div>

                                    </div>


                                  </div>

                                </div>
                              </div>



                            </div>
                     
    
                          </div>
                        </div>
                      </div> */}




      {/* modal end */}

      <CookieConsent
        location="bottom"
        buttonText="I accept"
        declineButtonText="I decline"
        cookieName="myAwesomeCookieName2"
        onAccept={handleAccept}
        onDecline={handleDecline}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>




      {/*  */}

    </>
  );
};

export default Footer;

import React, { useEffect, useRef, useState } from 'react';
import '../../../node_modules/bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'
import Frame1 from '../../../src/pages/registration-steper/registerimage/Frame1.svg'
import Frame2 from '../../../src/pages/registration-steper/registerimage/Frame3.svg'
import Frame3 from '../../../src/pages/registration-steper/registerimage/Frame4.svg'
import Frame4 from '../../../src/pages/registration-steper/registerimage/Frame5.svg'
import Frame5 from '../../../src/pages/registration-steper/registerimage/Frame6.svg'

import Frame6 from '../../../src/pages/registration-steper/registerimage/Frame7.svg'
import Form from 'react-bootstrap/Form';
import { SiReacthookform } from "react-icons/si";
import { Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import '../../../node_modules/react-phone-input-2/lib/style.css'
const HorizontalLinearStepper = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const showTab = (n) => {
    const tabs = document.getElementsByClassName("tab");
    tabs[n].style.display = "block";

    if (n === 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }

    if (n === (tabs.length - 1)) {
      document.getElementById("nextBtn").innerHTML = "Submit";
    } else {
      document.getElementById("nextBtn").innerHTML = "Next";
    }

    fixStepIndicator(n);
  };

  const nextPrev = (n) => {
    const tabs = document.getElementsByClassName("tab");

    if (n === 1 && !validateForm()) return false;

    tabs[currentTab].style.display = "none";
    setCurrentTab(currentTab + n);

    if (currentTab >= tabs.length) {
      document.getElementById("regForm").submit();
      return false;
    }

    showTab(currentTab);
  };

  const validateForm = () => {
    const tabs = document.getElementsByClassName("tab");
    const inputs = tabs[currentTab].getElementsByTagName("input");

    let valid = true;
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value === "") {
        inputs[i].className += " invalid";
        valid = false;
      }
    }

    if (valid) {
      document.getElementsByClassName("step")[currentTab].className += " finish";
    }

    return valid;
  };

  const fixStepIndicator = (n) => {
    const steps = document.getElementsByClassName("step");
    for (let i = 0; i < steps.length; i++) {
      steps[i].className = steps[i].className.replace(" active", "");
    }
    steps[n].className += " active";
  };


  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-4 bg-light mob-hide'>
          <div className='py-3'>
            <p className='registertext'>How NayeeDisha helps you in Admission</p>
            <div className='row mt-3'>
              <div className='col-md-6'>
                <img src={Frame1} alt="img" className='w-100' />
              </div>

              <div className='col-md-6'>
                <img src={Frame2} alt="img" className='w-100' />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-md-6'>
                <img src={Frame3} alt="img" className='w-100' />
              </div>

              <div className='col-md-6'>
                <img src={Frame4} alt="img" className='w-100' />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-md-6'>
                <img src={Frame5} alt="img" className='w-100' />
              </div>

              <div className='col-md-6'>
                <img src={Frame6} alt="img" className='w-100' />
              </div>
            </div>
          </div>
          <p>"Our team of seasoned professionals, well-versed in the intricacies of international education"</p>
        </div>
        <div className='col-md-8'>
          <div id="stepper1" className="bs-stepper">
            <div className="bs-stepper-header d-none">



            </div>
            <div className="bs-stepper-content mt-3">

              <b className=''><SiReacthookform className='circal' />&nbsp; Register Now To Apply</b>
              <hr></hr>
              <form id='registration'>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Full Name <spam className="spamred">*</spam></Form.Label>
                      <Form.Control type="text" placeholder="Full Name" />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Email address <spam className="spamred">*</spam></Form.Label>
                      <Form.Control type="email" placeholder="name@example.com" />
                    </Form.Group>
                  </div>
                </div>
                
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Mobile Number <spam className="spamred">*</spam></Form.Label>
                      <PhoneInput  inputProps={{ name: 'phone_number', required: true, autoFocus: true
  }}
/>
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>City You Leave in <spam className="spamred">*</spam></Form.Label>
                      <Form.Control type="email" placeholder="City You Leave in" />
                    </Form.Group>
                  </div>
                  <div className='mt-3 w-20 submit_button mb-3'>
            <input type="submit" value='Submit' className="btn btn-accent viewallbutton wave_talk text-white submit_button" />
          </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default HorizontalLinearStepper;

import { React, useState, useEffect } from 'react';
import forwardarrow from '../img/forward-arrow.svg';
import '../ReadMoreButton/index';
import universitylogo from '../img/university-logo.png';
import loanimg from '../img/loan-img.svg';
import testimg from '../img/test-img.svg';
import rec1 from '../img/rec1.jpg';
import dimg from '../img/d-img.jpg';
import Form from 'react-bootstrap/Form';
import { Triangle } from 'react-loader-spinner'
import galleriestab from '../img/galleries-tab.avif';
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import Slider from "react-slick";
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';

import ReactReadMoreReadLess from 'react-read-more-read-less';

const University = () => {
    const { uid } = useParams();
    const [univerSity, setUniverSity] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [UniversityInfo, setUniversityInfo] = useState(null);
    const [ranking, setRanking] = useState(null);
    const [courses, setCourses] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [faculty, setFaculty] = useState(null);
    const [hostel, setHostel] = useState(null);
    const [Gallery, setGallery] = useState(null);
    const [colleges, setColleges] = useState(null);
    const [news, setNews] = useState(null);
    const [qandA, setQandA] = useState(null);
    const [FAQ, setFAQ] = useState(null);
    const [AdmissionandEligibility, setAdmissionandEligibility] = useState(null);
    const [Campus, setCampus] = useState(null)








    useEffect(() => {
        setIsLoading(true);
        fetch(`https://webcrmapi.nayeedisha.in/api/university-details/${uid}`)
            .then((response) => response.json())

            .then((data) => {
                console.log(data);
                if (Array.isArray(data.UniversityInfo)) {
                    setUniversityInfo(data.UniversityInfo[0]);
                } else {
                    setUniversityInfo(data.UniversityInfo);
                }

                setCourses(data.Courses);
                setRanking(data.Ranking);
                setReviews(data.Reviews);
                setFaculty(data.Faculty);
                setHostel(data.Hostel);
                setGallery(data.Gallery);
                setColleges(data.Colleges);
                setNews(data.News);
                setFAQ(data.FAQ);
                setAdmissionandEligibility(data.AdmissionandEligibility)
                setCampus(data.Campus)


                setIsLoading(false);
                console.log(data);
                console.log('University Data:', data.UniversityInfo);
                console.log('Courses Data:', data.Courses);
                console.log('Ranking', data.Ranking);
                console.log('Reviews', data.Reviews);
                console.log('Faculty', data.Faculty);
                console.log('Hostel', data.Hostel);
                console.log('Gallery', data.Gallery);
                console.log('Colleges', data.Colleges);
                console.log('News', data.News);
                console.log('Faq', data.AdmissionandEligibility)
                console.log('Campus', data.Campus)







            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching university details:', error);
            });
    }, [uid]);
    const getUgcapproved = (value) => {
        return value === 1 ? 'YES' : 'No';
    };

    //   word test limit

    const truncateWords = (text, limit) => {
        if (!text) return ''; // Check if text is not provided
        const words = text.split(' ');
        return words.slice(0, limit).join(' ');
    };


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
    };

    return (
        <>


            {/* section1 */}

            {isLoading ? (
                <div className='d-flex justify-content-center py-5'><Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                /></div>
            ) : (
                UniversityInfo && (
                    <div className='university-section pt-5 pb-5' key={UniversityInfo.uid}>

                        <div className='container'>

                            <div className='first-section d-flex'>
                                <div className='second-section'>
                                    <img src={`https://media.nayeedisha.in${UniversityInfo.logo}`} alt="" style={{ width: "" }} />

                                </div>&nbsp;&nbsp;

                                <div className='third-section pt-2'>
                                    <p> {UniversityInfo.name ? UniversityInfo.name : 'Null'}</p>


                                    <div className='mysvgs '>
                                        <img src="" alt='' />
                                        <span className='para1'>Eligibility:<span className='ugc'> {UniversityInfo.minimum_percentage_for_mbbs ? UniversityInfo.minimum_percentage_for_mbbs : 'Not Found'} % in PCB </span></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className='para1'>Intake for MBBS:<span className='ugc'> {UniversityInfo.intake_for_mbbs ? UniversityInfo.intake_for_mbbs : 'Not Found'} </span></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className='para1'>NEET Requirement:<span className='ugc'> {UniversityInfo.neet_requirement ? UniversityInfo.neet_requirement : 'NULL'} </span></span>&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>


                                </div>


                            </div>
                        </div>

                        <div className='container'>
                            <div className='col-md-5 offset-md-7 '>

                                <div className='icon-btn d-flex '>

                                    <button type="button" class="btn btn-primary mt-2">

                                        <div className='d-flex justify-content-between'>
                                            <div className='apply-content'> Apply Now  </div>
                                            <div >  <img src={forwardarrow} class="" alt="" /></div></div>  </button>&nbsp;&nbsp;



                                    <button type="button" class="btn mt-2" id="btnrs2">
                                        <a href={`https://media.nayeedisha.in${UniversityInfo.brochure}`} download target="_blank">
                                            <div className='d-flex justify-content-between '>
                                                <div> Download Brochure </div>
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M2.66675 10.6693V11.3333C2.66675 11.8638 2.87746 12.3725 3.25253 12.7475C3.62761 13.1226 4.13632 13.3333 4.66675 13.3333H11.3334C11.8638 13.3333 12.3726 13.1226 12.7476 12.7475C13.1227 12.3725 13.3334 11.8638 13.3334 11.3333V10.6667M8.00008 3V10.3333M8.00008 10.3333L10.3334 8M8.00008 10.3333L5.66675 8" stroke="#2D69F0" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </a>
                                    </button>

                                </div>
                            </div>
                        </div>


                    </div>

                )
            )}

            {isLoading ? (
                <p></p>
            ) : (
                UniversityInfo && (
                    <div className='container pt-5'>

                        <nav>
                            <div class="nav nav-tabs mobile-navflex" id="nav-tab" role="tablist" >
                                <button class="nav-link active" id="nav-overview-tab" data-bs-toggle="tab" data-bs-target="#nav-overview" type="button" role="tab" aria-controls="nav-overview" aria-selected="true">Introduction</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-courses-tab" data-bs-toggle="tab" data-bs-target="#nav-courses" type="button" role="tab" aria-controls="nav-courses" aria-selected="false">Courses & Fees</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-program-tab" data-bs-toggle="tab" data-bs-target="#nav-program" type="button" role="tab" aria-controls="nav-program" aria-selected="false">Admission & Eligibility</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-admission-tab" data-bs-toggle="tab" data-bs-target="#nav-admission" type="button" role="tab" aria-controls="nav-admission" aria-selected="false">Advantage & Disadvantage</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-scholarship-tab" data-bs-toggle="tab" data-bs-target="#nav-scholarship" type="button" role="tab" aria-controls="nav-scholarship" aria-selected="false">Campus Info</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-placement-tab" data-bs-toggle="tab" data-bs-target="#nav-placement" type="button" role="tab" aria-controls="nav-placement" aria-selected="false">Ranking</button>&nbsp;&nbsp;
                                <button class="nav-link" id="nav-reviews-tab" data-bs-toggle="tab" data-bs-target="#nav-reviews" type="button" role="tab" aria-controls="nav-reviews" aria-selected="false">Reviews</button>&nbsp;&nbsp;

                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-overview" role="tabpanel" aria-labelledby="nav-overview-tab">


                                <div className='container mt-5'>

                                    <div className="card">
                                        <div className="card-body overview">
                                            <h2 data-toggle="tooltip" data-placement="top" title="Tooltip on top">Introduction About {UniversityInfo.name ? UniversityInfo.name : 'Null'}</h2>
                                            <div className='overview-para'>
                                            <div dangerouslySetInnerHTML={{ __html: UniversityInfo.aboutuniversity || '' }} />
                                            </div>
                                        </div>





                                    </div>
                                </div>

                                <div className='container divider-crd'>
                                    <div class="card mt-5 mb-5">
                                        <div class="card-body">



                                            <h5><b>At Glance: </b></h5>

                                            {isLoading ? (
                                                <div className='text-center'>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (

                                                <div className='row'>

                                                    {courses.map((data) => {
                                                        return (

                                                            <div>

                                                                {/* <p>{data.about_course ? data.about_course : 'Null'}</p> */}

                                                                <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-4'>

                                                                    <table class="table table-bordered">
                                                                        <thead>
                                                                            <tr>

                                                                                <th scope="col">Intake for MBBS in {UniversityInfo.name ? UniversityInfo.name : 'Null'}</th>
                                                                                <td scope="col">{UniversityInfo.intake_for_mbbs ? UniversityInfo.intake_for_mbbs : 'Null'}</td>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>

                                                                                <th>Minimum percentage for MBBS in {UniversityInfo.name ? UniversityInfo.name : 'Null'}</th>
                                                                                <td>{UniversityInfo.minimum_percentage_for_mbbs ? UniversityInfo.minimum_percentage_for_mbbs : 'Null'}% in PCB</td>

                                                                            </tr>
                                                                            <tr>

                                                                                <th>NEET Requirement</th>
                                                                                <td>{UniversityInfo.neet_requirement ? UniversityInfo.neet_requirement : 'Null'}</td>

                                                                            </tr>

                                                                            <tr>

                                                                                <th>Recognition of {UniversityInfo.name ? UniversityInfo.name : 'Null'}</th>
                                                                                <td>{UniversityInfo.recognition ? UniversityInfo.recognition : 'Null'}</td>

                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>


                                                            </div>

                                                        )
                                                    })
                                                    }

                                                </div>
                                            )}








                                        </div>
                                    </div>
                                </div>

                                <div className='container divider-crd'>
                                    <div class="card mt-5 mb-5">
                                        <div class="card-body">



                                            <h5>Key Features of {UniversityInfo.name}</h5>

                                            {isLoading ? (
                                                <div className='text-center'>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (

                                                <div className='row'>



                                                    <div>
                                                        <p>{UniversityInfo.facts ? UniversityInfo.facts : 'Null'}</p>




                                                    </div>



                                                </div>
                                            )}








                                        </div>
                                    </div>
                                </div>



                                {/*  */}
                                <section id=''>
                                    <div className='container divider-crd'>
                                        <div class="card mt-5 mb-5">
                                            <div class="card-body">
                                                <h5>Courses & Fees</h5>

                                                {isLoading ? (
                                                    <div className='text-center'>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (

                                                    <div className='row'>



                                                        {courses.map((data) => {
                                                            return (

                                                                <div>
                                                                    <p><b>About</b> :{data.about_course ? data.about_course : 'Null'}</p>
                                                                    <hr></hr>
                                                                    <h5 className='mt-3'>{data.name ? data.name : 'Null'}, Highlights :</h5>
                                                                    <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-4'>


                                                                        <table class="table table-bordered">
                                                                            <thead>
                                                                                <tr>

                                                                                    <th scope="col">Course Name</th>
                                                                                    <td scope="col">{data.name ? data.name : 'Null'}</td>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>

                                                                                    <th>Duration</th>
                                                                                    <td>{data.duration ? data.duration : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Teacher Student Ratio</th>
                                                                                    <td>{data.teacher_student_ratio ? data.teacher_student_ratio : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>
                                                                                    <th>Total Fees</th>
                                                                                    <td>{data.totalfee ? data.totalfee : 'Null'}</td>
                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Tuition Fees</th>
                                                                                    <td>{data.tuition_fees ? data.tuition_fees : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Hostel Fees</th>
                                                                                    <td>{data.hostel_fees ? data.hostel_fees : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Food Cost</th>
                                                                                    <td>{data.food_cost ? data.food_cost : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Living Cost</th>
                                                                                    <td>{data.living_cost ? data.living_cost : 'Null'}</td>

                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </div>


                                                                </div>

                                                            )
                                                        })
                                                        }



                                                    </div>
                                                )}








                                            </div>
                                        </div>
                                    </div>
                                </section>



                                <div className='container p-4 fee-section  '>
                                    <div className=''>



                                        <div className='row fee-section-2 p-4 '>
                                            <div className='col-md-6 pt-3'>

                                                <h3>Worried About College Fees?</h3>
                                                <p>Convert your Upfront Fees in to Simple, Convenient & Affordable EMIs</p>

                                                <button type="button" class="btn ">Apply For Education Loan</button>



                                            </div>

                                            <div className='col-md-6 text-center'>

                                                <img src={loanimg} alt="" style={{ width: "" }} />


                                            </div>

                                        </div>
                                    </div>


                                </div>

                                <div className='container divider-crd'>
                                    <div class="card mt-5 mb-5">
                                        <div class="card-body">



                                            <h5>Admission & Eligibility</h5>

                                            {isLoading ? (
                                                <div className='text-center'>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (

                                                <div className='row'>



                                                    {AdmissionandEligibility.map((data) => {
                                                        return (

                                                            <div>

                                                                <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-4'>
                                                                    <p>{data.admission_process}</p>


                                                                </div>


                                                            </div>

                                                        )
                                                    })
                                                    }



                                                </div>
                                            )}








                                        </div>
                                    </div>
                                </div>
                                {/* advantage vs Disadvantage */}
                                <div className='container divider-crd'>
                                    <div class="card mt-5 mb-5">
                                        <div class="card-body">



                                            <h5>Advantage & Disadvantage</h5>

                                            {isLoading ? (
                                                <div className='text-center'>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (

                                                <div className='row'>

                                                    <table   class="table table-bordered p-0 table-responsive-sm">
                                                        <thead>
                                                            <tr>

                                                                <th className='w-50' scope="col">Advantage</th>
                                                                <th className='w-50' scope="col">Disadvantage</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                <div dangerouslySetInnerHTML={{ __html: UniversityInfo.advantage || '' }} />
                                                                    </td>
                                                                <td>
                                                                <div dangerouslySetInnerHTML={{ __html: UniversityInfo.disadvantage || '' }} />
                                                                    
                                                                   
                                                                    </td>

                                                            </tr>




                                                        </tbody>
                                                    </table>





                                                </div>
                                            )}








                                        </div>
                                    </div>
                                </div>


                                {/* close */}

                                {/* campus */}
                                <div className='container divider-crd'>
                                    <div class="card mt-5 mb-5">
                                        <div class="card-body">



                                            <h5>Campus of {UniversityInfo.name}</h5>

                                            {isLoading ? (
                                                <div className='text-center'>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (


                                                <div className='row'>



                                                    {Campus.map((data) => {
                                                        return (

                                                            <div>
                                                                <p>{data.about_campus}</p>

                                                                <h5>Hostel :</h5>
                                                                <p>{data.hostel}</p>
                                                                <h5>Infrastructure :</h5>
                                                                <p>{data.infrastructure}</p>
                                                                <h5>Student Life :</h5>
                                                                <p>{data.student_life}</p>
                                                                <h5>Food :</h5>
                                                                <p>{data.indian_food}</p>

                                                                <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-4'>

                                                                    {/* <table class="table table-bordered">
                                                                        <thead>
                                                                            <tr>

                                                                                <th scope="col">Advantage</th>
                                                                                <th scope="col">Disadvantage</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>

                                                                                <td>{UniversityInfo.advantage ? UniversityInfo.advantage : 'Null'}</td>
                                                                                <td>{UniversityInfo.disadvantage ? UniversityInfo.disadvantage : 'Null'}</td>

                                                                            </tr>




                                                                        </tbody>
                                                                    </table> */}


                                                                </div>


                                                            </div>

                                                        )
                                                    })
                                                    }



                                                </div>
                                            )}








                                        </div>
                                    </div>
                                </div>


                                <div className='container'>
                                    <div class="card mt-5">
                                        <div class="card-body">
                                            <h5>Affiliated Colleges</h5>
                                            {isLoading ? (
                                                <div className='text-center'>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <div className='row'>

                                                    {colleges.map((data) => {
                                                        return (
                                                            <div className='col-md-3'>
                                                                <div className="card" id="icons-crd" style={{ width: '100%' }}>

                                                                    <img src={lister1} class="card-img-top" alt="" />

                                                                    <div className="card-body iconerss-card">
                                                                        <img src={iconlister} class="" alt="" />
                                                                        {/* 
                                                               <h5 className="mt-4">Kazan Federal <br></br>University</h5>
                                                               */}
                                                                        <Link to={`/university/${data.slug}`}>
                                                                            <h5 className="mt-4">{data.name}</h5>
                                                                        </Link>
                                                                        <div>
                                                                            <div className='mysvg'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                                </svg>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                                </svg>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                                </svg>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                                </svg>
                                                                                <span id='one-svg'>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                                    </svg>
                                                                                </span>
                                                                                &nbsp;&nbsp; 64 Reviews
                                                                            </div>
                                                                            <div className=' pt-2'>
                                                                                <p className='para1'>UGC Approved-<span className='ugc'> {data.ugcapproved === 1 ? "Yes" : "No"} </span></p>



                                                                                <p className='para1'>College Type-<span className='ugc'> {data.typeofuniversity ? data.typeofuniversity : 'Not found'} </span></p>

                                                                            </div>
                                                                            <div className=''>
                                                                                <button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                                                                    Apply Now &nbsp;&nbsp;&nbsp;
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                                                                    </svg>
                                                                                </button>
                                                                                &nbsp;&nbsp;
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }

                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>


                                {/* section */}







                                {/* section top courses*/}






                                {/* related news section */}

                                <div className='container mt-5 news-section'>
                                    <h3 className='text-center'>Related News</h3>


                                    <div className='row'>



                                        {news.map((data) => {
                                            const formatDate = (dateString) => {
                                                const date = new Date(dateString);
                                                const day = date.getDate().toString().padStart(2, '0');
                                                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
                                                const year = date.getFullYear();
                                                return `${day}-${month}-${year}`;
                                            };
                                            return (
                                                <div className='col-md-3 news-crd '>

                                                    <div className="card" style={{ width: "" }}>
                                                        <img src={rec1} alt="" style={{ width: "" }} />
                                                        <div className="card-body">
                                                            <a href='' >  <b className="card-text text-capitalize">

                                                                {truncateWords(data.title, 10)}
                                                            </b>
                                                            </a>
                                                            <p className='text-capitalize'>{data.content}</p>

                                                            <p className='news-crd2'>
                                                                Created At : {formatDate(data.created_at)}</p>
                                                        </div>
                                                    </div>


                                                </div>

                                            )
                                        })
                                        }


                                    </div>


                                </div>



                                {/* contact section */}

                                <div className='container mt-5 mb-5 cont-section'>


                                    <div class="card">
                                        <div class="card-body">

                                            <div className='row'>

                                                <h5> {UniversityInfo.name} Contact Number, Address & Location Map</h5>

                                                <div className='col-md-6 pt-5'>

                                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                    </svg>&nbsp;&nbsp; {UniversityInfo.university_contactno}

                                                    </p>

                                                    <hr></hr>

                                                    <a href={`mailto:${UniversityInfo.university_email}`}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 14.998"><path fill="#1A1A1A" id="Forma_1" data-name="Forma 1" d="M351,1046.782l6.727,3.491,3.49,6.726L366,1042Zm2.286.194,10.444-3.329-5.76,5.759Zm7.737,7.738-2.43-4.684,5.76-5.76Z" transform="translate(-351 -1042.001)"></path></svg> &nbsp;&nbsp;
                                                        {UniversityInfo.university_email}
                                                    </a>

                                                    <hr></hr>

                                                    <a href={`${UniversityInfo.university_url}`} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe2" viewBox="0 0 16 16">
                                                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                                                    </svg>&nbsp;&nbsp; {UniversityInfo.university_url}
                                                    </a>
                                                    <hr></hr>
                                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 17.143"><path fill="#1A1A1A" id="Icon_awesome-building" data-name="Icon awesome-building" d="M14.6,16.071h-.67V.8a.8.8,0,0,0-.8-.8H1.875a.8.8,0,0,0-.8.8V16.071H.4a.4.4,0,0,0-.4.4v.67H15v-.67A.4.4,0,0,0,14.6,16.071ZM4.286,2.545a.4.4,0,0,1,.4-.4H6.027a.4.4,0,0,1,.4.4V3.884a.4.4,0,0,1-.4.4H4.688a.4.4,0,0,1-.4-.4Zm0,3.214a.4.4,0,0,1,.4-.4H6.027a.4.4,0,0,1,.4.4V7.1a.4.4,0,0,1-.4.4H4.688a.4.4,0,0,1-.4-.4Zm1.741,4.955H4.688a.4.4,0,0,1-.4-.4V8.973a.4.4,0,0,1,.4-.4H6.027a.4.4,0,0,1,.4.4v1.339A.4.4,0,0,1,6.027,10.714Zm2.545,5.357H6.429V13.259a.4.4,0,0,1,.4-.4H8.17a.4.4,0,0,1,.4.4Zm2.143-5.759a.4.4,0,0,1-.4.4H8.973a.4.4,0,0,1-.4-.4V8.973a.4.4,0,0,1,.4-.4h1.339a.4.4,0,0,1,.4.4Zm0-3.214a.4.4,0,0,1-.4.4H8.973a.4.4,0,0,1-.4-.4V5.759a.4.4,0,0,1,.4-.4h1.339a.4.4,0,0,1,.4.4Zm0-3.214a.4.4,0,0,1-.4.4H8.973a.4.4,0,0,1-.4-.4V2.545a.4.4,0,0,1,.4-.4h1.339a.4.4,0,0,1,.4.4Z"></path></svg>&nbsp;&nbsp;{UniversityInfo.
                                                        street}
                                                    </p>
                                                </div>
                                                <div className='col-md-6 pt-2'>

                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.491344607126!2d77.36129867429273!3d28.61503258487734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5427b444ccf%3A0xc90a6dd87b5bff14!2sRirabh!5e0!3m2!1sen!2sin!4v1700564660094!5m2!1sen!2sin" width="100%" height="350" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                                                </div>

                                            </div>





                                        </div>
                                    </div>

                                </div>




                                {/* Gallery Section */}

                                <div className='container slider-section mb-5'>

                                    <div className='card pb-4'>

                                        <div className='card-body'>

                                            <h3>Images & Video Gallery</h3>



                                            <div className="App  pt-3" style={{ background: "" }}>
                                                {isLoading ? (
                                                    <div className='text-center'>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (
                                                    <Slider {...settings}>

                                                        {Gallery.map((data) => {
                                                            console.log('aniket', data);
                                                            return (

                                                                <div className='col-md-4 px-3'>

                                                                    <div><img src={`https://media.nayeedisha.in${data.image}`} alt="" style={{ width: "100%" }} /></div>

                                                                </div>


                                                            )
                                                        })
                                                        }


                                                    </Slider>
                                                )}
                                            </div>


                                        </div>







                                    </div>


                                </div>






                                {/* FAQ SECTION */}

                                <div className='container faq-section'>


                                    <div className='card'>

                                        <div className='card-body'>

                                            <h3>FAQs about {UniversityInfo.name} University</h3>

                                            {isLoading ? (
                                                <div className='text-center'>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <div className='roww'>

                                                    {FAQ.map((data) => {
                                                        console.log('aniket', data);
                                                        return (
                                                            <div className="accordion mt-4" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header mb-2" id="headingOne">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse open" data-bs-target={`#collapseOne${data.id}`} aria-expanded="true" aria-controls="collapseOne">
                                                                            {data.question ? data.question : 'Null'}
                                                                        </button>
                                                                    </h2>
                                                                    <div id={`collapseOne${data.id}`} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                        <div class="accordion-body">
                                                                            {data.answer ? data.answer : 'Null'}
                                                                        </div>
                                                                    </div>
                                                                </div><hr></hr>

                                                            </div>
                                                        )
                                                    })
                                                    }

                                                </div>
                                            )}

                                        </div>
                                    </div>


                                </div>










                                {/* Register With Us */}



                                <div class="container mt-5 mb-5 reg-section">

                                    <div className='text-center '> <h3>Register with us</h3> </div>


                                    <div className="row mt-4">
                                        <div className="col-md-2 mt-3">
                                            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" />
      </Form.Group> */}
                                            <Form.Control type="text" placeholder="Name" />
                                        </div>
                                        <div className="col-md-2 mt-3">
                                            <Form.Group className="" controlId="exampleForm.ControlInput1">

                                                <Form.Control type="email" placeholder="Mobile" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                            <Form.Group className="" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="email" placeholder="Email" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected> Select Level </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                            <Form.Group className="mb-3" controlId="">

                                                <select class="form-select" aria-label="Default select example">
                                                    <option selected>Select Stream</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                            <button type="button" class="btn btn-primary">Submit details</button>
                                        </div>
                                    </div>
                                </div>




                            </div>

                            {/*  */}
                            {/*  */}
                            {/* COURSE PAGES 1 */}

                            <div class="tab-pane fade" id="nav-courses" role="tabpanel" aria-labelledby="nav-courses-tab">


                                <section id="courses">
                                    <div className='container divider-crd'>
                                        <div class="card mt-5 mb-5">
                                            <div class="card-body">
                                                <h5>Courses & Fees</h5>

                                                {isLoading ? (
                                                    <div className='text-center'>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (

                                                    <div className='row'>



                                                        {courses.map((data) => {
                                                            return (

                                                                <div>
                                                                    <p><b>About</b> :{data.about_course ? data.about_course : 'Null'}</p>
                                                                    <hr></hr>
                                                                    <h5 className='mt-3'>{data.name ? data.name : 'Null'}, Highlights :</h5>
                                                                    <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-4'>


                                                                        <table class="table table-bordered">
                                                                            <thead>
                                                                                <tr>

                                                                                    <th scope="col">Course Name</th>
                                                                                    <td scope="col">{data.name ? data.name : 'Null'}</td>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>

                                                                                    <th>Duration</th>
                                                                                    <td>{data.duration ? data.duration : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Teacher Student Ratio</th>
                                                                                    <td>{data.teacher_student_ratio ? data.teacher_student_ratio : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>
                                                                                    <th>Total Fees</th>
                                                                                    <td>{data.totalfee ? data.totalfee : 'Null'}</td>
                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Tuition Fees</th>
                                                                                    <td>{data.tuition_fees ? data.tuition_fees : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Hostel Fees</th>
                                                                                    <td>{data.hostel_fees ? data.hostel_fees : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Food Cost</th>
                                                                                    <td>{data.food_cost ? data.food_cost : 'Null'}</td>

                                                                                </tr>

                                                                                <tr>

                                                                                    <th>Living Cost</th>
                                                                                    <td>{data.living_cost ? data.living_cost : 'Null'}</td>

                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </div>


                                                                </div>

                                                            )
                                                        })
                                                        }



                                                    </div>
                                                )}








                                            </div>
                                        </div>
                                    </div>
                                </section>



                                {/* another section */}
                                <div className='fee-section mt-5 '>
                                    <div className='container p-4'>


                                        <div className='row fee-section-2 p-4 '>
                                            <div className='col-md-6 pt-3'>

                                                <h3>Can’t find right guidance?</h3>
                                                <p>No Problem! Speak to our experts safely from your home.</p>

                                                <button type="button" class="btn ">Request a Call Back</button>


                                            </div>

                                            <div className='col-md-6 text-center'>

                                                <img src={loanimg} alt="" style={{ width: "" }} />


                                            </div>

                                        </div>
                                    </div>


                                </div>

                                {/*  */}
                                {/*  */}






                            </div>

                            {/* SECTION 1 END */}

                            <div class="tab-pane fade" id="nav-program" role="tabpanel" aria-labelledby="nav-program-tab">

                                {/* streams section */}

                                <section id="admission">
                                    <div className='container divider-crd'>
                                        <div class="card mt-5 mb-5">
                                            <div class="card-body">



                                                <h5>Admission & Eligibility</h5>

                                                {isLoading ? (
                                                    <div className='text-center'>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (

                                                    <div className='row'>



                                                        {AdmissionandEligibility.map((data) => {
                                                            return (

                                                                <div>

                                                                    <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-4'>
                                                                        <p>{data.admission_process}</p>


                                                                    </div>


                                                                </div>

                                                            )
                                                        })
                                                        }



                                                    </div>
                                                )}








                                            </div>
                                        </div>
                                    </div>
                                </section>


                                {/* end stream section */}








                            </div>

                            {/*  */}
                            {/* SECTION 1 END */}

                            <div class="tab-pane fade" id="nav-admission" role="tabpanel" aria-labelledby="nav-admission-tab">

                                {/* streams section */}

                                <section id="advantage">
                            <div className='container divider-crd'>
                                <div class="card mt-5 mb-5">
                                    <div class="card-body">



                                        <h5>Advantage & Disadvantage</h5>

                                        {isLoading ? (
                                            <div className='text-center'>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        ) : (

                                            <div className='row'>

                                                <table class="table table-bordered p-0">
                                                    <thead>
                                                        <tr>

                                                            <th scope="col">Advantage</th>
                                                            <th scope="col">Disadvantage</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>{UniversityInfo.advantage ? UniversityInfo.advantage : 'Null'}</td>
                                                            <td>{UniversityInfo.disadvantage ? UniversityInfo.disadvantage : 'Null'}</td>

                                                        </tr>




                                                    </tbody>
                                                </table>





                                            </div>
                                        )}








                                    </div>
                                </div>
                            </div>
                        </section>


                                {/* end stream section */}








                            </div>


                            {/*  */}





                            <div class="tab-pane fade" id="nav-scholarship" role="tabpanel" aria-labelledby="nav-scholarship-tab">


                                <section id="campus">
                                    <div className='container divider-crd'>
                                        <div class="card mt-5 mb-5">
                                            <div class="card-body">



                                                <h5>Campus of {UniversityInfo.name}</h5>

                                                {isLoading ? (
                                                    <div className='text-center'>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (


                                                    <div className='row'>



                                                        {Campus.map((data) => {
                                                            return (

                                                                <div>
                                                                    <p>{data.about_campus}</p>

                                                                    <h5>Hostel :</h5>
                                                                    <p>{data.hostel}</p>
                                                                    <h5>Infrastructure :</h5>
                                                                    <p>{data.infrastructure}</p>
                                                                    <h5>Student Life :</h5>
                                                                    <p>{data.student_life}</p>
                                                                    <h5>Food :</h5>
                                                                    <p>{data.indian_food}</p>

                                                                    <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-4'>

                                                                        <table class="table table-bordered">
                                                                            <thead>
                                                                                <tr>

                                                                                    <th scope="col">Advantage</th>
                                                                                    <th scope="col">Disadvantage</th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>

                                                                                    <td>{UniversityInfo.advantage ? UniversityInfo.advantage : 'Null'}</td>
                                                                                    <td>{UniversityInfo.disadvantage ? UniversityInfo.disadvantage : 'Null'}</td>

                                                                                </tr>




                                                                            </tbody>
                                                                        </table>


                                                                    </div>


                                                                </div>

                                                            )
                                                        })
                                                        }



                                                    </div>
                                                )}








                                            </div>
                                        </div>
                                    </div>
                                </section>




                            </div>





                            <div class="tab-pane fade" id="nav-placement" role="tabpanel" aria-labelledby="nav-placement-tab">


                                <p>lorem ipsum</p>




                            </div>




                            <div class="tab-pane fade" id="nav-cutoff" role="tabpanel" aria-labelledby="nav-cutoff-tab">


                                {/* streams section */}

                                <div className='container mt-5 mb-5 stream-section'>

                                    <div className='card'>

                                        <div className='card-body'>


                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="exam-tab" data-bs-toggle="tab" data-bs-target="#exam" type="button" role="tab" aria-controls="exam" aria-selected="true">EXAM</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="degree-tab" data-bs-toggle="tab" data-bs-target="#degree" type="button" role="tab" aria-controls="degree" aria-selected="false">DEGREE</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="course-tab" data-bs-toggle="tab" data-bs-target="#course" type="button" role="tab" aria-controls="course" aria-selected="false">COURSE </button>
                                                </li>
                                            </ul>
                                            <div class="tab-content px-3 mt-4" id="myTabContent">
                                                <div class="tab-pane fade show active" id="exam" role="tabpanel" aria-labelledby="exam-tab">
                                                    <input type="checkbox" id="cat" name="cat" value="edu" />
                                                    <label for="cat"> &nbsp; CAT</label><br></br>

                                                </div>
                                                <div class="tab-pane fade" id="degree" role="tabpanel" aria-labelledby="degree-tab">
                                                    <input type="checkbox" id="mba" name="mba" value="edu" />
                                                    <label for="mba"> &nbsp; M.B.A. (Master of Business Administration)</label> &nbsp;&nbsp;&nbsp;


                                                </div>
                                                <div class="tab-pane fade" id="course" role="tabpanel" aria-labelledby="course-tab">
                                                    <input type="checkbox" id="course1" name="course1" value="edu" />
                                                    <label for="course1"> &nbsp; MBA in Marketing</label>&nbsp;&nbsp;&nbsp;
                                                    <input type="checkbox" id="course1" name="course1" value="edu" />
                                                    <label for="course1"> &nbsp; MBA in Finance
                                                    </label>&nbsp;&nbsp;&nbsp;
                                                    <input type="checkbox" id="course1" name="course1" value="edu" />
                                                    <label for="course1"> &nbsp; MBA in Human Resource Management</label>&nbsp;&nbsp;&nbsp;
                                                    <input type="checkbox" id="course1" name="course1" value="edu" />
                                                    <label for="course1"> &nbsp; MBA in Business Analytics</label>&nbsp;&nbsp;

                                                </div>
                                            </div>


                                            <hr></hr>
                                            <div className='pt-4'>
                                                <p><b>Applied Filters:</b>
                                                    &nbsp;  No Filters Applied</p></div>





                                        </div>

                                    </div>

                                </div>


                                {/* end stream section */}


                                <div className='container mb-5 cut-off-section '>


                                    <div className='card'>


                                        <div className='card-body'>
                                            <h5>DIT University (CAT, MBA in Marketing, Cutoff, Round 1, 2018)</h5>
                                            <hr></hr>
                                            <table class="table">
                                                <thead>
                                                    <tr>

                                                        <th scope="col">Category</th>
                                                        <th scope="col">Closing Percentile</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>

                                                        <td>General</td>
                                                        <td>70</td>

                                                    </tr>


                                                </tbody>
                                            </table>






                                        </div>










                                    </div>





                                </div>


























                            </div>


                            <div class="tab-pane fade" id="nav-campus" role="tabpanel" aria-labelledby="nav-campus-tab">


                                <p>campus page</p>




                            </div>



                            <div class="tab-pane fade" id="nav-gallery" role="tabpanel" aria-labelledby="nav-gallery-tab">


                                {/* first section */}

                                <div className='congtainer mt-5 mb-5'>

                                    <div className='card'>

                                        <div className='card-body'>

                                            <div className='row'>

                                                <div className='col mt-3'>
                                                    <img src={galleriestab} alt="" style={{ width: "" }} />
                                                </div>

                                                <div className='col mt-3'>
                                                    <img src={galleriestab} alt="" style={{ width: "" }} />
                                                </div>

                                                <div className='col mt-3'>
                                                    <img src={galleriestab} alt="" style={{ width: "" }} />
                                                </div>
                                                <div className='col mt-3'>
                                                    <img src={galleriestab} alt="" style={{ width: "" }} />
                                                </div>
                                                <div className='col mt-3'>
                                                    <img src={galleriestab} alt="" style={{ width: "" }} />
                                                </div>



                                            </div>
















                                        </div>















                                    </div>











                                </div>


                            </div>

                            <div class="tab-pane fade" id="nav-reviews" role="tabpanel" aria-labelledby="nav-reviews-tab">


                                <p>Reviews</p>




                            </div>


                            {/*  */}
                        </div>


                    </div>



                )
            )}
        </>
    );
};

export default University;

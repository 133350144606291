import React from 'react'

function Index() {
    return (
       <>
       <h1>Thank you </h1>
       </> 
    )
}

export default Index

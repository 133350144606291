import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { IoMdInformationCircle } from "react-icons/io";
import Educationlogo from "../img/educationlogo.png";
import {
  FaHome,
  FaGem,
  FaList,
  FaSpellCheck,
  FaRegLaughWink,
  FaHeart,
  FaRegSun,
  FaRegUserCircle,
  FaHeadphonesAlt,
  FaProductHunt,
  FaBuilding
} from "react-icons/fa";
import { MdMiscellaneousServices } from "react-icons/md";
import { LuRussianRuble } from "react-icons/lu";
import { FcAbout } from "react-icons/fc";
import { FaFlag } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import searchicon from "../components/search-icon.png";

import ReactSearchBox from "react-search-box";


const Aside = ({ collapsed, toggled, handleToggleSidebar, handleToggle }) => {
  const searchData = [
    {
      key: "ani",
      value: "Aniket",
      url: "https://word.com/"
    },
    {
      key: "jane",
      value: "Jane Doe",
      url: "https://facebook.com/"
    },
    {
      key: "mary",
      value: "Mary Phillips",
      url: "https://example.com/mary"
    },
    {
      key: "robert",
      value: "Robert",
      url: "https://example.com/robert"
    },
    {
      key: "karius",
      value: "Karius",
      url: "https://example.com/karius"
    }
  ];
  const onSelect = (record) => {
    window.location.href = record.url;
    console.log(record);
  };

  const renderOption = (record, index) => {
    return (
      <div key={index} onClick={() => onSelect(record)}>
        <span>{record.value}</span>
        <span style={{ marginLeft: '5px', color: '#999' }}>{record.url}</span>
      </div>
    );
  };

  return (
    
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <img src={Educationlogo} alt="logo"></img>
        </div>



        {/*  */}
        <div className='p-2'>

        <ReactSearchBox
      placeholder="Search for John, Jane or Mary"
      data={searchData}
      onSelect={onSelect}
      renderOption={renderOption}
    />



        </div>





      </SidebarHeader>

      <SidebarContent>

        <Menu iconShape="">
        <Link onClick={() => handleToggle()} to={"/"}> <MenuItem icon={<FaHome />} > Home</MenuItem></Link>
        <Link onClick={() => handleToggle()} to={"/about-us"}> <MenuItem icon={<IoMdInformationCircle />}> About Nayee Disha</MenuItem></Link>
        <Link onClick={() => handleToggle()} to={"/services"}> <MenuItem icon={<MdMiscellaneousServices />} > Our Services</MenuItem></Link>


          <SubMenu
            suffix={<span className="badge yellow"></span>}
            title={"Study Abroad"}
            icon={<FaFlag />}
          >
            <SubMenu
              suffix={<span className="badge yellow"></span>}
              title={"Russia"}
              icon={<LuRussianRuble />}
            >
              <Link onClick={() => handleToggle()} to={"/mbbs-in-bashkir-state-medical-university"}>
                <MenuItem>{" MBBS in Bashkir State Medical University Russia"} </MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/mbbs-in-chuvash-state-medical-university-russia"}>
                <MenuItem>{"MBBS in Chuvash State Medical University Russia"}</MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/mbbs-in-omsk-state-medical-university-russia"}>
                <MenuItem>{"MBBS in OMSK State Medical University Russia"} </MenuItem>
              </Link>

              <Link onClick={() => handleToggle()} to={"/mbbs-in-crimea-federal-university-russia"}>
                <MenuItem>{"MBBS in Crimea Federal University Russia"} </MenuItem>
              </Link>
            </SubMenu>
          </SubMenu>


        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <Link to="/contact-us" className="sidebar-btn" rel="noopener noreferrer">
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {/* <Button Button variant="contained" color="secondary">
                <FaHeadphonesAlt className="svgwhite" /> &nbsp; Get Support
              </Button> */}
            </span>
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;

import { React, useEffect, useState, Component, Fragment } from "react";
import { Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import loger1 from '../img/loger1.png';
import apiUrl from '../setting/apiConfig';


function Exam() {
    const [isLoading, setIsLoading] = useState(true);

    const [exams, setExams] = useState([]);
    useEffect(() => {
        fetch(apiUrl + 'exam-details')
           .then((response) => response.json())
           .then((data) => {
              setExams(data);
              setIsLoading(false);
              console.log('Exams Data', data);
           })
           .catch((error) => {
              setIsLoading(false);
              console.error('Error fetching data:', error);
           });
     }, []);
    return (
        <div className="container pt-5 pb-5  ">

        {/* my coder */}
        {isLoading ? (
           <div className='text-center'>
              <Spinner animation="border" role="status">
                 <span className="visually-hidden">Loading...</span>
              </Spinner>
           </div>
        ) : (


           <div className='row'>

{exams.map((examsdata) => {
return (
    <div className="col-md-4 mt-3">
        <div class="card  tabber" style={{ width: '100%' }}>
            <div class="card-body  ">
                <div className="d-flex logers">
                   
                    &nbsp;&nbsp;
                    <div className="mt-2">
                        <h5 className="p-1 ">{examsdata.mode_of_exam}</h5>
                        <p className="exam-text">{examsdata.exam_name ? examsdata.exam_name : 'Null'}</p>
                    </div>
                </div>
                <div className="row next-pr mt-3">
                    <div className="col-md-6">
                        <p>Total Seats</p>
                    </div>
                    <div className="col-md-6 text-end nexter-hr">
                        <h5>{examsdata.total_seats ? examsdata.total_seats : 'Null'}</h5>
                    </div>
                </div>
                <div className="row next-pr">
                    <div className="col-md-6">
                        <p>Exam Date</p>
                    </div>
                    <div className="col-md-6 text-end nexter-hr">
                        <h5>{examsdata.exam_start_date}</h5>
                    </div>
                </div>
                <div className="row next-pr">
                    <div className="col-md-6">
                        <p>Exam Level</p>
                    </div>
                    <div className="col-md-6 text-end nexter-hr">
                        <h5>{examsdata.exam_level}</h5>
                    </div>
                </div>
                <div className="d-flex">
                    <button type="button" class="btn st-butss p-2">
                        Application Process &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="#222222" />
                        </svg>
                    </button>
                    &nbsp;&nbsp;
                    <Link to={`/exams-details/${examsdata.slug}`}> <button type="button" class="btn st-butss p-2">
                        Exam info &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="#222222" />
                        </svg>
                    </button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
)
})}



           </div>

        )}
     </div>
    )
}

export default Exam
